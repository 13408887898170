import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        noPic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "date": "2017-02-18",
  "title": "CSS width vs flex-basis",
  "description": "Understand one of the most important parts of CSS Flexbox",
  "promo": "flexbox",
  "color": "#ffa200"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`What's the difference? This is by far the most common flexbox question I get. In fact I remember googling this very thing myself back when I was still just guessing with flexbox. Lemme answer this and hopefully spare you some frustration as you learn flexbox!`}</p>
    <h2>{`Flex Items Formula`}</h2>
    <p>{`Flex item (the DOM elements inside of a flex container) sizing follows this specific formula:`}</p>
    <p><inlineCode parentName="p">{`content`}</inlineCode>{` —> `}<inlineCode parentName="p">{`width`}</inlineCode>{` —> `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` (limted by `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` & `}<inlineCode parentName="p">{`min-width`}</inlineCode>{`)`}</p>
    <p>{`For determining the size of a flex item the `}<em parentName="p">{`final`}</em>{` `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` bound by `}<inlineCode parentName="p">{`min-width`}</inlineCode>{` and `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` is all that matters.`}</p>
    <p className="note">
  NOTE: The flexbox spec calls this the "hypothetical main size", but it's a lot
  simpler to think of it as the final flex-basis.
    </p>
    <p>{`If no `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` is specified, then the `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` falls back to the item's `}<inlineCode parentName="p">{`width`}</inlineCode>{` property.`}</p>
    <p>{`If no `}<inlineCode parentName="p">{`width`}</inlineCode>{` is specified, then the `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` falls back to the computed width of the item's contents.`}</p>
    <p>{`Let's illustrate this formula by putting some flex items into a 1000px flex container:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.container {
  display: flex;
  width: 1000px;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/1.png",
        "alt": "flex container"
      }}></img></p>
    <h2>{`Setting a Width`}</h2>
    <p>{`Let's put some 200px by 200px items that specify a `}<inlineCode parentName="p">{`width`}</inlineCode>{` into our flex container.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.item {
  width: 200px;
  height: 200px;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/2.png",
        "alt": "flex items"
      }}></img></p>
    <p>{`Our flex container has plenty of room so our items fit nicely into it:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/3.png",
        "alt": "flex items"
      }}></img></p>
    <p>{`In this example our items' `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` wasn't specified, so it defaulted to `}<inlineCode parentName="p">{`flex-basis: auto`}</inlineCode>{`, which falls back to the `}<inlineCode parentName="p">{`width`}</inlineCode>{` (200px).`}</p>
    <p>{`So far, setting a `}<inlineCode parentName="p">{`width`}</inlineCode>{` is equivalent to setting a `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`.`}</p>
    <h2>{`Setting a Flex Basis`}</h2>
    <p>{`Let's see what happens when we set a `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` on these items that already have a `}<inlineCode parentName="p">{`width`}</inlineCode>{` specified.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.item {
  width: 30px;
  flex-basis: 250px;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/7.png",
        "alt": "flex items"
      }}></img></p>
    <p>{`As you can see, when a `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` is specified, the `}<inlineCode parentName="p">{`width`}</inlineCode>{` of our boxes is ignored, so we don't even need to specify it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.item {
  flex-basis: 250px;
}
`}</code></pre>
    <p>{`Our final `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` for each item is 250px. And since we have enough room for all of those, they'll fit nicely into their flex container:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/8.png",
        "alt": "flex items"
      }}></img></p>
    <p>{`Remember the flex items formula:`}</p>
    <p><inlineCode parentName="p">{`content`}</inlineCode>{` —> `}<inlineCode parentName="p">{`width`}</inlineCode>{` —> `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` (limted by `}<inlineCode parentName="p">{`max-width`}</inlineCode>{`  & `}<inlineCode parentName="p">{`min-width`}</inlineCode>{`)`}</p>
    <p>{`The only thing that matters is this final flex-basis. A best practice is to just use `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` instead of width or height. Especially since Safari still has an old flexbox bug where it won't apply flex-shrink properly to items that use `}<inlineCode parentName="p">{`height`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`.`}</p>
    <h2>{`flex-basis is Limited by max-width`}</h2>
    <p>{`The final `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` value is limited by both the `}<inlineCode parentName="p">{`min-width`}</inlineCode>{` and `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` of the item. Watch what happens when we set a `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` limit to our flex items:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.item {
  flex-basis: 250px;
  max-width: 100px;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/9.png",
        "alt": "flex items"
      }}></img></p>
    <p>{`Even though our `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` was set to 250px, it hit the 100px `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` limit. So our final `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` in this case is 100px, and our items will fit into the flex container like so:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/10.png",
        "alt": "flex items"
      }}></img></p>
    <p>{`Now let's set a `}<inlineCode parentName="p">{`min-width`}</inlineCode>{` and see how that limits our final `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.item {
  flex-basis: 100px;
  min-width: 250px;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/7.png",
        "alt": "flex items"
      }}></img></p>
    <p>{`Even though we specified 100px for our `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`, it couldn't go lower than the 250px `}<inlineCode parentName="p">{`min-width`}</inlineCode>{` limit. So our final `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` is 250px and our flex items fit perfectly into the container:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/8.png",
        "alt": "flex items"
      }}></img></p>
    <h2>{`So What Exactly is flex-basis?`}</h2>
    <p>{`Now we understand that `}<inlineCode parentName="p">{`width`}</inlineCode>{` is just a fallback when `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` is missing, and `}<inlineCode parentName="p">{`min-width`}</inlineCode>{` and `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` are just upper and lower limits for the final `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`. So what exactly is `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`?`}</p>
    <p>{`You probably noticed that in all of our illustrations we visualized the size of the flex items `}<strong parentName="p">{`before`}</strong>{` they got put into the flex container. We did that because that's exactly what `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` is: `}<strong parentName="p">{`the size of flex items before they are placed into a flex container`}</strong>{`. It's the `}<em parentName="p">{`ideal`}</em>{` or `}<em parentName="p">{`hypothetical`}</em>{` size of the items. But `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` is `}<strong parentName="p">{`not a guaranteed size`}</strong>{`! As soon as the browser places the items into their flex container, things change. In some of our examples above you saw that the flex items fit perfectly into their flex container, because the total sum of all the items' final `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` was the exact width of our container (1000px). That's great when that happens, but often times the flex container won't have enough space, or will have extra space, after all its items' `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` values are added up.`}</p>
    <h2>{`When Not Enough Space`}</h2>
    <p>{`Say we wanted to put even more of these `}<inlineCode parentName="p">{`flex-basis: 200px`}</inlineCode>{` items into our container:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/4.png",
        "alt": "flex items"
      }}></img></p>
    <p>{`Before going into the container they would each take up 200px, or 1600px total. But our container only has 1000px available. When there's not enough room for all the items' full `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` (200px each) then flex items by default will `}<em parentName="p">{`shrink`}</em>{` to fit:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/5.png",
        "alt": "flex items"
      }}></img></p>
    <p>{`All of the items started out at 200px wide, but since we were short on space they shrunk at an even rate until they all fit (125px each). That `}<strong parentName="p">{`shrink rate`}</strong>{` is what the `}<inlineCode parentName="p">{`flex-shrink`}</inlineCode>{` ratio is all about. You can override the default to make specific items shrink faster (higher ratio) or slower (lower ratio) or not shrink at all (`}<inlineCode parentName="p">{`flex-shrink: 0`}</inlineCode>{`).`}</p>
    <h2>{`When Extra Space Available`}</h2>
    <p>{`Often we'll have plenty of space left over after all the items' final `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` is added up.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.item {
  flex-basis: 100px;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/9.png",
        "alt": "flex items"
      }}></img></p>
    <p>{`We can instruct our flex items to grow to fill the available space once they're placed into the flex container. That's what the `}<inlineCode parentName="p">{`flex-grow`}</inlineCode>{` property is all about. The default value is `}<inlineCode parentName="p">{`0`}</inlineCode>{`, meaning the item won't grow. Let's set each item to `}<inlineCode parentName="p">{`flex-grow: 1`}</inlineCode>{` (all grow at the same rate) to fill up the remaining space:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.item {
  flex-basis: 100px;
  flex-grow: 1;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/flexbox/8.png",
        "alt": "flex items"
      }}></img></p>
    <p>{`Growing and shrinking is a huge part of the coolness of flexbox, and is what makes flexbox so great for responsive UI design. `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` Mastery Game covers `}<inlineCode parentName="p">{`flex-shrink`}</inlineCode>{` and `}<inlineCode parentName="p">{`flex-grow`}</inlineCode>{` in more detail.`}</p>
    <h2>{`Width vs flex-basis`}</h2>
    <p>{`Hopefully now you see the difference between `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`, and how `}<inlineCode parentName="p">{`min-width`}</inlineCode>{` and `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` affect the final flex-basis. All of the above applies the same to `}<inlineCode parentName="p">{`height`}</inlineCode>{` when your `}<inlineCode parentName="p">{`flex-direction`}</inlineCode>{` is `}<inlineCode parentName="p">{`column`}</inlineCode>{` or `}<inlineCode parentName="p">{`column-reverse`}</inlineCode>{`. If you want to master all of flexbox once and for all, I made `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` Mastery Game for you, where you shoot zombies by controlling a crossbow with flexbox code. Happy hunting!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      